<template>
  <v-container class="general list">
    <page-title
      :component="'AudienceListPage'"
      :translatable-tooltip="$t('audience_list_page.page_title_tooltip')"
    >
      <template v-slot:actions>
        <VideoTutorial
          :component="'AudienceListPage'"
          :title="$t('audiences-tutorial-title')"
        />
      </template>
    </page-title>
    <transition
      name="fade"
      mode="out-in"
    >
      <v-layout
        v-if="!isLoading && !audiences.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t('audience_list_page.nothing_created') }}
        </v-flex>
      </v-layout>
    </transition>

    <v-layout
      v-if="audiences.length"
      class="row wrap"
    >
      <v-flex
        v-for="(audience, i) in audiences"
        :key="`audience-${i}`"
        class="xs12 px-4"
        :class="{ loading: isLoading }"
      >
        <v-layout class="row py-4 align-items-center">
          <font-awesome-icon
            v-if="audience.group_plugin_id"
            :icon="['fa', 'lock']"
            class="mr-3 grey--text"
          />
          <v-flex class="xs12">
            <a
              class="sw-h5 font-weight-light"
              @click.stop="editAudience(audience.id)"
            >
              {{ audience.name }}
            </a>
          </v-flex>
          <v-btn
            icon
            class="my-0 mx-0"
            @click="deleteAudience(audience)"
            :disabled="!!audience.group_plugin_id"
          >
            <font-awesome-icon
              :class="[!!audience.group_plugin_id ? 'grey--text' : 'sw-accent']"
              icon="trash"
            />
          </v-btn>
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex
        v-if="totalPages > 1"
        class="xs12 pt-4 text-xs-center"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listAudiences()"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <AppSpeedDial
      @click="$router.push({ name: 'audiences_create' })"
      :hint="$t('audience_list_page.speed_dial_hint')"
    />

    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    audiences: [],
    page: 1,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
  },
  mounted() {
    this.page = this.currentPage;
    this.listAudiences();
  },
  methods: {
    async listAudiences() {
      try {
        const params = [
          this.groupId,
          {
            lang: this.appContentLanguage,
            page: this.page,
            per_page: this.perPage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupAudiences.list(...params);

        this.isLoading = false;

        this.audiences = response.data.data;
        this.totalPages = response.data.pagination.total_pages;
        this.query();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    editAudience(audienceId) {
      this.$router
        .push({
          name: 'audiences_edit',
          params: {
            group_id: this.groupId,
            audience_id: audienceId,
          },
        })
        .catch(() => {});
    },
    query() {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.page,
          }),
        })
        .catch(() => {});
    },
    async deleteAudience(audience) {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t('audienceDeleteConfirmationTextLine', {
          name: `${audience.name}`,
        }),
        this.$t('common.no'),
        this.$t('common.yes'),
      );

      if (!isConfirmed) {
        return;
      }

      try {
        const specs = [this.groupId, audience.id];

        this.isLoading = true;

        await this.$api.groupAudiences.delete(...specs);

        this.isLoading = false;

        this.$store.dispatch('addNotification', {
          message: this.$t('audience_page.audience_delete_message', {
            name: audience.name,
          }),
        });

        this.listAudiences();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
